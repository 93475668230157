import React from 'react';

export const Button = ({ children, width, onClick }) => {
  return (
    <button
      style={{ width, height: '30px', borderRadius: '15px', cursor: 'pointer' }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
