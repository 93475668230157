import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import Button from './Button';

import './App.css';

function App() {
  const defaultButtonMessage = 'Save';
  const [buttonMessage, setButtonMessage] = useState(defaultButtonMessage);

  const defaultBackgroundColor = '#fff';
  const [color, setColor] = useState(
    localStorage.getItem('background') || defaultBackgroundColor
  );

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    let timeout;
    if (buttonMessage !== defaultButtonMessage) {
      timeout = setTimeout(() => {
        setButtonMessage(defaultButtonMessage);
        setCollapsed(true);
      }, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [buttonMessage]);

  useEffect(() => {}, [color]);

  const getArrowClass = () => {
    return (
      'collapse-arrow ' +
      (collapsed ? 'collapse-arrow--right' : 'collapse-arrow--left')
    );
  };

  const getSidebarClass = () => {
    return 'sidebar ' + (collapsed ? 'sidebar--hide' : 'sidebar--show');
  };

  const handleButtonClick = () => {
    localStorage.setItem('background', color);
    setButtonMessage(`${color} saved!`);
  };

  return (
    <>
      <div style={{ backgroundColor: color }} className='background'>
        <div className={getSidebarClass()}>
          <div className='color-picker-wrapper'>
            <SketchPicker
              color={color}
              onChange={(color) => {
                setColor(color.hex);
              }}
            />
            <div className='button-wrapper'>
              <Button width='100%' onClick={handleButtonClick}>
                {buttonMessage}
              </Button>
            </div>
          </div>
          <div
            className='collapse-arrow-wrapper'
            onClick={() => {
              setCollapsed((value) => !value);
            }}
          >
            <div className={getArrowClass()}></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
